<template>
  <div class="link-body">
    <template v-if="$store.state.isLogin">
      <div class="link-module" :class="draggable ? 'sort-drag' : ''" v-for="(item, index) in list" :key="item.name">
        <div class="link-catalog" @dragstart="catalogDragstart(index)" @dragenter="catalogDragenter($event, index)"
          @dragover.prevent :draggable="draggable">
          <div class="catalog-info" @click="catalogClick(index)">
            <i class="info-icon" :class="item.linkShow ? 'info-icon-open' : 'info-icon-close'"></i>
            <p class="info-title">{{ item.name }}</p>
          </div>
          <div class="catalog-operate" v-if="isCreator && !draggable">
            <span class="operate-item operate-item-edit" @click="editLinkModule(item)">
              编辑
            </span>
            <span class="operate-item operate-item-delete" @click="deleteLinkModule(item.id)">
              删除
            </span>
            <span class="operate-item operate-item-add" @click="addLinkDialog(item)">
              添加小节
            </span>
          </div>
        </div>
        <div class="link-content" :class="item.linkShow ? 'link-content-open' : ''">
          <div class="link-item" v-for="(item1, index1) in item.activity_link" :key="item1.name"
            @dragstart="dragstart(index, index1)" @dragenter="dragenter($event, index, index1)" @dragover.prevent
            :draggable="draggable">
            <div class="item-info">
              <i class="info-icon" :class="item1.type | typeIconFilter"></i>
              <div class="info-content">
                <h3 class="content-type">{{ item1.type_name }}</h3>
                <i class="content-line"></i>
                <p class="content-title">{{ item1.name }}</p>
              </div>
              <span class="info-state" :class="item1.status | stateFilter('class')">
                {{ item1.status | stateFilter("name") }}
              </span>
            </div>
            <div class="item-operate" v-if="!draggable">
              <router-link class="operate-item operate-item-details" :to="linkDetailUrl(item1)" v-if="activityInfoData.checkUserJoin == 1 ||
      activityInfoData.checkUserJoin == 4
      ">
                查看
              </router-link>
              <span class="operate-item operate-item-details" @click="linkDetail()" v-else>查看</span>
              <router-link class="operate-item operate-item-edit ml40" :to="editLinkUrl(item1)" v-if="isCreator">
                编辑
              </router-link>
              <span class="operate-item operate-item-delete ml40" @click="deleteLink(item1.id)" v-if="isCreator">
                删除
              </span>
            </div>
          </div>
          <CommonEmpty v-if="item.activity_link && item.activity_link.length == 0" TextVal="暂无环节" :key="index">
          </CommonEmpty>
        </div>
      </div>
      <CommonEmpty v-if="list.length == 0" TextVal="暂无章节"></CommonEmpty>
      <span class="link-module-add" @click="addLinkModule()" v-if="isCreator && !draggable">
        添加环节
      </span>
    </template>
    <CommonEmpty TextVal="暂未登录" v-else></CommonEmpty>

    <el-dialog :title="`${this.linkModuleForm.id ? '编辑' : '添加'}环节`" :center="true" width="800px"
      :close-on-click-modal="false" :visible.sync="dialogLinkModuleVisible" @closed="dialogLinkModuleClose"
      class="public-dialog dialog-linkModule">
      <el-form :model="linkModuleForm" :rules="linkModuleRules" ref="linkModule" label-width="0" @submit.native.prevent>
        <el-form-item label="" prop="name">
          <el-input v-model="linkModuleForm.name" placeholder="请输入环节名称" maxlength="30" show-word-limit></el-input>
        </el-form-item>
        <div class="form-operate">
          <span class="operate-button-1 mr60" @click="linkModuleCancel()">取消</span>
          <span class="operate-button-2" @click="linkModuleConfirm()">
            {{ linkModuleForm.id ? "修改" : "确定" }}
          </span>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog title="添加小节" :center="true" width="1000px" :close-on-click-modal="false"
      :visible.sync="dialogLinkVisible" class="public-dialog dialog-addLink">
      <div class="dialog-module">
        <p class="module-title">添加内容</p>
        <ul class="module-list">
          <li class="module-list-item" v-for="(item, index) in contentTypeList" :key="index">
            <router-link :to="addLinkUrl(item)" class="module-list-link">
              <i class="item-icon" :class="item.class"></i>
              <p class="item-title">{{ item.name }}</p>
              <i class="item-icon-el">+</i>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="dialog-module">
        <p class="module-title">添加互动</p>
        <ul class="module-list">
          <li class="module-list-item" v-for="(item, index) in interactTypeList" :key="index">
            <router-link :to="addLinkUrl(item)" class="module-list-link">
              <i class="item-icon" :class="item.class"></i>
              <p class="item-title">{{ item.name }}</p>
              <i class="item-icon-el">+</i>
            </router-link>
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryString } from "@/utils/utils.js";
export default {
  props: {
    //活动信息数据
    activityInfoData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      dialogLinkModuleVisible: false, //添加环节模块弹窗
      dialogLinkVisible: false, //添加环节弹窗
      //添加环节模块弹窗
      linkModuleForm: {
        activity_id: 0,
        is_personid: 1,
        name: "",
      },
      linkModuleRules: {
        name: [{ required: true, message: "请输入环节名称", trigger: "blur" }],
      },
      list: [], //列表
      listBackup: [], //备份列表
      //内容类型环节列表
      contentTypeList: [
        {
          name: "资源浏览",
          typeId: 1,
          class: "item-icon-1",
        },
        {
          name: "在线直播",
          typeId: 7,
          class: "item-icon-7",
        },
        {
          name: "资源上传",
          typeId: 2,
          class: "item-icon-2",
        },
        {
          name: "教研会议",
          typeId: 8,
          class: "item-icon-8",
        },
      ],
      //互动类型环节列表
      interactTypeList: [
        {
          name: "讨论",
          typeId: 3,
          class: "item-icon-3",
        },
        {
          name: "投票",
          typeId: 5,
          class: "item-icon-5",
        },
        {
          name: "问卷",
          typeId: 4,
          class: "item-icon-4",
        },
        {
          name: "协作文档",
          typeId: 6,
          class: "item-icon-6",
        },
      ],
      chapterId: 0, //环节目录id
      isCreator: false, //是否创造者
      draggable: false, //元素是否可拖动
      dragClassify: 1, //拖动分类
      catalogDragIndex: 0, //目录元素拖动索引
      //元素拖动索引
      dragIndex: {
        index: 0,
        index1: 0,
      },
    };
  },
  created() {
    const query = this.$route.query;
    this.linkModuleForm.activity_id = query.activityId;
    this.linkModuleForm.is_personid = query.is_from ? query.is_from : "";
    if (localStorage.getItem("id") == this.activityInfoData.user_id) {
      this.isCreator = true;
    }
    if (this.$store.state.isLogin) {
      this.getList();
    }
  },
  methods: {
    //添加环节模块
    addLinkModule() {
      this.dialogLinkModuleVisible = true;
    },
    //编辑环节模块
    editLinkModule(e) {
      this.dialogLinkModuleVisible = true;
      this.$nextTick((res) => {
        this.linkModuleForm.name = e.name;
        this.linkModuleForm.id = e.id;
      });
    },
    //删除环节模块
    deleteLinkModule(id) {
      this.$confirm("删除该章节, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          this.$axios
            .delete("activityChapter/del", {
              params: { is_personid: this.linkModuleForm.is_personid, id },
            })
            .then((res) => {
              const data = res.data;
              if (data.code == 900) {
                this.getList();
              }
            });
        })
        .catch(() => { });
    },
    //添加环节弹窗
    addLinkDialog(e) {
      this.chapterId = e.id;
      this.dialogLinkVisible = true;
    },
    //添加环节链接
    addLinkUrl(e) {
      let query = {
        activityId: this.linkModuleForm.activity_id,
        linkTypeId: e.typeId,
        chapterId: this.chapterId,
        is_from: this.linkModuleForm.is_personid,
        linkTypeName: encodeURIComponent(e.name),
      };
      return `/activityStudyCreateLink${queryString(query)}`;
    },
    //编辑环节链接
    editLinkUrl(e) {
      let query = {
        activityId: this.linkModuleForm.activity_id,
        linkTypeId: e.type,
        linkId: e.id,
        chapterId: this.chapterId,
        is_from: this.linkModuleForm.is_personid,
        linkTypeName: encodeURIComponent(e.type_name),
      };
      return `/activityStudyCreateLink${queryString(query)}`;
    },
    //删除环节
    deleteLink(id) {
      this.$confirm("删除该环节, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          this.$axios
            .delete(`/activityLink/${id}`, {
              params: { is_personid: this.linkModuleForm.is_personid },
            })
            .then((res) => {
              const data = res.data;
              if (data.code == 900) {
                this.getList();
              }
            });
        })
        .catch(() => { });
    },
    //添加环节模块弹窗关闭回调
    dialogLinkModuleClose() {
      if (this.linkModuleForm.hasOwnProperty("id")) {
        Reflect.deleteProperty(this.linkModuleForm, "id");
      }
      this.$refs["linkModule"].resetFields();
    },
    //添加环节模块弹窗取消
    linkModuleCancel() {
      this.dialogLinkModuleVisible = false;
    },
    //添加环节模块弹窗确定
    linkModuleConfirm() {
      this.$refs["linkModule"].validate((valid) => {
        if (valid) {
          let apiFunction = "";
          if (this.linkModuleForm.hasOwnProperty("id")) {
            apiFunction = this.$axios.put("activityChapter/edit", this.linkModuleForm);
          } else {
            apiFunction = this.$axios.post("activityChapter/add", this.linkModuleForm);
          }
          apiFunction.then((res) => {
            const data = res.data;
            if (data.code == 900) {
              this.getList();
              this.linkModuleCancel();
            }
          });
        }
      });
    },
    //获取列表
    getList() {
      this.$axios
        .get("activityChapter/get_list", { params: this.linkModuleForm })
        .then((res) => {
          const data = res.data;
          if (data.code == 900) {
            let list = data.data.data;
            if (list.length > 0) {
              list.forEach((item) => {
                if (item.activity_link.length == 0) {
                  item.linkShow = false;
                } else {
                  item.linkShow = true;
                }
              });
            }
            this.list = list;
          }
        });
    },
    //环节模块点击
    catalogClick(index) {
      this.list[index].linkShow = !this.list[index].linkShow;
    },
    //环节详情链接
    linkDetailUrl(e) {
      let path = "";
      let query = {
        activityId: this.linkModuleForm.activity_id,
        is_from: this.linkModuleForm.is_personid,
        id: e.id,
      };
      switch (e.type) {
        //资源浏览环节
        case 1:
          path = "resourcebrowsing";
          break;
        //资源上传环节
        case 2:
          path = "resourceuploadbrowsing";
          break;
        //讨论环节
        case 3:
          path = "discussbrowsing";
          break;
        //问卷环节
        case 4:
          path = "questionbrowsing";
          break;
        //投票环节
        case 5:
          path = "votebrowsing";
          break;
        //协作文档环节
        case 6:
          path = "collaborationbrowsing";
          break;
        //在线直播环节
        case 7:
          path = "livebrowsing";
          break;
        //教研会议环节
        case 8:
          path = "meetbrowsing";
          break;
        //在线文档环节
        case 9:
          path = "OnLineWord";
          break;
      }
      return `/${path}${queryString(query)}`;
    },
    //环节详情查看按钮
    linkDetail() {
      let confirmButtonText = "报名参与";
      let cancelButtonText = "取消";
      let showConfirmButton = false;
      let showCancelButton = true;
      if (
        (this.activityInfoData.checkUserJoin == 3 ||
          this.activityInfoData.checkUserJoin == 5) &&
        this.activityInfoData.status != 3
      ) {
        cancelButtonText = "暂不参与";
        showConfirmButton = true;
      }
      if (
        (this.activityInfoData.checkUserJoin == 3 ||
          this.activityInfoData.checkUserJoin == 5) &&
        this.activityInfoData.status == 3
      ) {
        cancelButtonText = "活动已结束";
      }
      if (this.activityInfoData.checkUserJoin == 2) {
        cancelButtonText = "已报名，审核中";
      }
      if (this.activityInfoData.checkUserJoin == 6) {
        showCancelButton = false;
      }
      this.$confirm(
        `<p style="font-size:18px;margin-bottom:20px;">您不是本次活动的参与者，暂无查看权限！<p/>`,
        "",
        {
          confirmButtonText,
          cancelButtonText,
          showConfirmButton,
          showCancelButton,
          dangerouslyUseHTMLString: true,
          closeOnClickModal: false,
          center: true,
        }
      )
        .then(() => {
          this.$parent.onJoin();
        })
        .catch(() => { });
    },
    //目录拖动开始
    catalogDragstart(index) {
      this.catalogDragIndex = index;
      this.dragClassify = 1;
    },
    //目录拖动进入
    catalogDragenter(e, index) {
      e.preventDefault();
      // 避免源对象触发自身的dragenter事件
      if (
        this.dragClassify == 1 &&
        this.draggable == true &&
        this.catalogDragIndex !== index
      ) {
        let list = JSON.parse(JSON.stringify(this.list));
        const moving = list[this.catalogDragIndex];
        list.splice(this.catalogDragIndex, 1);
        list.splice(index, 0, moving);
        this.list = list;
        // 排序变化后目标对象的索引变成源对象的索引
        this.catalogDragIndex = index;
      }
    },
    //环节拖动开始
    dragstart(index, index1) {
      this.dragIndex.index = index;
      this.dragIndex.index1 = index1;
      this.dragClassify = 2;
    },
    //环节拖动进入
    dragenter(e, index, index1) {
      e.preventDefault();
      // 避免源对象触发自身的dragenter事件
      if (
        this.dragClassify == 2 &&
        this.draggable == true &&
        this.dragIndex.index1 !== index1
      ) {
        let list = JSON.parse(JSON.stringify(this.list));
        let moving = list[this.dragIndex.index].activity_link[this.dragIndex.index1];
        list[this.dragIndex.index].activity_link.splice(this.dragIndex.index1, 1);
        //list[index].activity_link.data.splice(index1, 0, moving);
        list[this.dragIndex.index].activity_link.splice(index1, 0, moving);
        this.list = list;
        // 排序变化后目标对象的索引变成源对象的索引
        this.dragIndex.index = index;
        this.dragIndex.index1 = index1;
      }
    },
    //设置是否可以拖动状态
    async setDraggable(type) {
      if (type == "open") {
        this.listBackup = JSON.parse(JSON.stringify(this.list));
        this.draggable = true;
      }
      if (type == "cancel") {
        this.list = JSON.parse(JSON.stringify(this.listBackup));
        this.draggable = false;
      }
      if (type == "confirm") {
        await this.setSort();
      }
      return this.draggable;
    },
    async setSort() {
      let data = {
        activity_id: this.linkModuleForm.activity_id,
        is_personid: this.linkModuleForm.is_personid,
        chapter: [],
        link: [],
      };
      if (this.list.length > 0) {
        this.list.forEach((item, index) => {
          data.chapter.push({ id: item.id, sort: index + 1 });
          if (item.activity_link.length > 0) {
            item.activity_link.forEach((item, index) => {
              data.link.push({ id: item.id, sort: index + 1 });
            });
          }
        });
      }
      await this.$axios.put("/activityChapter/editSort", data).then((res) => {
        const data = res.data;
        if (data.code == 900) {
          this.draggable = false;
        }
      });
    },
  },
  filters: {
    //环节类型图标
    typeIconFilter(type) {
      return `info-icon-${type}`;
    },
    //环节状态
    stateFilter(state, type) {
      let value = "";
      if (type == "name") {
        if (state == 1) {
          value = "未开始";
        }
        if (state == 2) {
          value = "进行中";
        }
        if (state == 3) {
          value = "已结束";
        }
      }
      if (type == "class") {
        if (state == 1) {
          value = "info-state-started";
        }
        if (state == 2) {
          value = "info-state-ing";
        }
        if (state == 3) {
          value = "info-state-end";
        }
      }
      return value;
    },
  },
  watch: {
    //监听活动信息详情
    activityInfoData(e) {
      if (localStorage.getItem("id") == e.user_id) {
        this.isCreator = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.public-dialog {
  .el-dialog {
    border-radius: 20px;
    overflow: hidden;

    .el-dialog__header {
      height: 60px;
      padding: 0;
      background: #3489fe;

      .el-dialog__title {
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        line-height: 60px;
      }

      .el-dialog__headerbtn {
        .el-dialog__close {
          font-size: 20px;
          color: #fff;
          font-weight: bold;
        }
      }
    }
  }
}

.link-body {
  padding-bottom: 30px;

  .link-module {
    padding: 30px 40px 0 30px;

    .link-catalog {
      display: flex;

      .catalog-info {
        flex: 1;
        overflow: hidden;
        display: flex;
        align-items: center;
        margin-right: 25px;

        .info-icon {
          width: 20px;
          height: 20px;
          background-size: 100% !important;
          display: block;
          margin-right: 8px;
        }

        .info-icon-close {
          background: url("../../../assets/images/icon-close.png") no-repeat center;
        }

        .info-icon-open {
          background: url("../../../assets/images/icon-open.png") no-repeat center;
        }

        .info-title {
          flex: 1;
          overflow: hidden;
          font-size: 22px;
          font-weight: bold;
          color: #333333;
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .catalog-operate {
        width: 320px;
        display: flex;
        justify-content: space-between;

        .operate-item {
          height: 40px;
          border-radius: 40px;
          font-size: 18px;
          line-height: 40px;
          padding: 0 20px;
          display: inline-block;
          cursor: pointer;
        }

        .operate-item-edit {
          background: #ebf3ff;
          color: #3489fe;
        }

        .operate-item-delete {
          background: #fff4f4;
          color: #ff6868;
        }

        .operate-item-add {
          background: #ebf3ff;
          color: #3489fe;
        }
      }
    }

    .link-content {
      padding-top: 30px;
      display: none;

      .link-item {
        height: 50px;
        padding: 0 30px 0 20px;
        background: #f4f7ff;
        border-radius: 4px;
        display: flex;
        align-items: center;
        margin: 0 0 10px 28px;

        .item-info {
          flex: 1;
          overflow: hidden;
          display: flex;
          align-items: center;
          margin-right: 25px;

          .info-icon {
            width: 20px;
            height: 20px;
            background-size: 100% !important;
            display: block;
            margin-right: 8px;
          }

          .info-icon-1 {
            background: url("../../../assets/huodon_img/资源浏览@2x.png") no-repeat center;
          }

          .info-icon-2 {
            background: url("../../../assets/huodon_img/资源上传@2x.png") no-repeat center;
          }

          .info-icon-3 {
            background: url("../../../assets/huodon_img/讨论@2x.png") no-repeat center;
          }

          .info-icon-4 {
            background: url("../../../assets/huodon_img/问卷@2x.png") no-repeat center;
          }

          .info-icon-5 {
            background: url("../../../assets/huodon_img/投票@2x.png") no-repeat center;
          }

          .info-icon-6 {
            background: url("../../../assets/huodon_img/在线文档@2x.png") no-repeat center;
          }

          .info-icon-7 {
            background: url("../../../assets/huodon_img/在线直播@2x.png") no-repeat center;
          }

          .info-icon-8 {
            background: url("../../../assets/huodon_img/教研会议@2x.png") no-repeat center;
          }

          .info-content {
            max-width: 80%;
            overflow: hidden;
            display: flex;
            align-items: center;
            margin-right: 10px;

            .content-type {
              font-size: 18px;
              font-weight: bold;
              color: #333333;
            }

            .content-line {
              width: 1px;
              height: 16px;
              background: #333333;
              display: block;
              margin: 0 15px;
            }

            .content-title {
              flex: 1;
              overflow: hidden;
              font-size: 18px;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .info-state {
            width: 50px;
            height: 20px;
            border-radius: 10px 10px 10px 0;
            font-size: 12px;
            color: #ffffff;
            line-height: 20px;
            text-align: center;
            white-space: nowrap;
          }

          .info-state-started {
            background: #3489fe;
          }

          .info-state-ing {
            background: #ff9400;
          }

          .info-state-end {
            background: #cccccc;
          }
        }

        .item-operate {
          //width: 180px;
          display: flex;
          justify-content: space-between;

          .operate-item {
            font-size: 16px;
            color: #666666;
            cursor: pointer;
          }

          .operate-item-details {
            &:hover {
              color: #3489fe;
            }
          }

          .operate-item-edit {
            &:hover {
              color: #3489fe;
            }
          }

          .operate-item-delete {
            &:hover {
              color: #ff6868;
            }
          }
        }
      }
    }

    .link-content-open {
      display: grid;
    }
  }

  .link-module-add {
    height: 50px;
    background: #3489fe;
    border-radius: 4px;
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    line-height: 50px;
    padding: 0 22px;
    display: inline-block;
    cursor: pointer;
    margin: 30px 0 0 28px;
  }

  /deep/.public-dialog {
    .el-dialog__body {
      .dialog-module {
        .module-title {
          font-size: 20px;
          color: #333333;
          margin-bottom: 22px;
        }

        .module-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .module-list-item {
            width: 47%;
            height: 60px;
            background: #eef2fb;
            border-radius: 4px;
            overflow: hidden;
            margin-bottom: 20px;

            .module-list-link {
              height: 60px;
              padding: 0 30px;
              display: flex;
              align-items: center;

              .item-icon {
                width: 24px;
                height: 24px;
                background-size: 100% !important;
                display: block;
                margin-right: 15px;
              }

              .item-icon-1 {
                background: url("../../../assets/huodon_img/资源浏览@2x.png") no-repeat center;
              }

              .item-icon-2 {
                background: url("../../../assets/huodon_img/资源上传@2x.png") no-repeat center;
              }

              .item-icon-3 {
                background: url("../../../assets/huodon_img/讨论@2x.png") no-repeat center;
              }

              .item-icon-4 {
                background: url("../../../assets/huodon_img/问卷@2x.png") no-repeat center;
              }

              .item-icon-5 {
                background: url("../../../assets/huodon_img/投票@2x.png") no-repeat center;
              }

              .item-icon-6 {
                background: url("../../../assets/huodon_img/在线文档@2x.png") no-repeat center;
              }

              .item-icon-7 {
                background: url("../../../assets/huodon_img/在线直播@2x.png") no-repeat center;
              }

              .item-icon-8 {
                background: url("../../../assets/huodon_img/教研会议@2x.png") no-repeat center;
              }

              .item-title {
                flex: 1;
                overflow: hidden;
                font-size: 20px;
                font-weight: bold;
                color: #333333;
              }

              .item-icon-el {
                width: 24px;
                height: 24px;
                background: #3489fe;
                border-radius: 4px;
                display: block;
                font-size: 18px;
                font-weight: bold;
                color: #ffffff;
                font-style: normal;
                text-align: center;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }

  /deep/.dialog-linkModule {
    .el-dialog__body {
      padding: 50px 70px;
    }
  }

  /deep/.dialog-addLink {
    .el-dialog__body {
      padding: 30px 70px 10px 70px;
    }
  }

  .form-operate {
    display: flex;
    justify-content: center;

    .operate-button-1,
    .operate-button-2 {
      height: 50px;
      background: #b6becd;
      border-radius: 8px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      line-height: 50px;
      padding: 0 40px;
      display: inline-block;
      cursor: pointer;
    }

    .operate-button-2 {
      background: #3489fe;
    }
  }
}

.sort-drag {
  padding-bottom: 30px !important;
  margin-bottom: 10px;
  background: #ececec;

  .link-item {
    background: #fff !important;
  }

  .link-catalog,
  .link-item {
    cursor: move;
  }
}
</style>
